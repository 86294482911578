:root, html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #F6F6F9;

  --gray: #F0F0F0;
  --gray-2: #4F4F4F;
  --black: #000000;
  --white: #FFFFFF;
  --primary: #1771F1;
  --agri: #20BF55;
  --photogrammetry: #000000;
}

#root {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
